import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, Subscription, map, of, switchMap } from 'rxjs';

import { BaseFormComponent } from '@app/forms/formly/base-form/base-form';
import { ApiService } from '@app/services/api/api.service';
import { dropdownSeparatorValue } from '@app/ui/components/dropdown/dropdown.component';
import { CCNumberValidator, errorsFormTexts } from '@app/forms/formly/validators/validators';
import { scrollTo } from '@app/utils/utils';
import { Option } from '@app/forms/formly/formly-utils';
import { clientBalanceOption, newCreditCardOption } from '@app/utils/constants';
import { PortalCurrencyPipe } from '@app/ui/pipes/portal-currency.pipe';

@Component({
  selector: 'card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardFormComponent
  extends BaseFormComponent<CreditCardErrors | null>
  implements AfterViewInit, OnDestroy
{
  valueChanges$ = new Subscription();
  isSplittedPayment$ = new BehaviorSubject(false);

  usCountryCode = '';

  disabledFields: string[] = [];

  @Input() readAndAccept: boolean;
  @Input() creditCards: Option[];
  @Input() portalLink: string;
  @Input() clientBalanceAmount: number;
  @Input() maxBalanceAmount: number;
  @Input() totalAmount: number;

  @Output() valueChange = new EventEmitter();
  @Output() setReadAndAcceptError = new EventEmitter();
  @Output() back = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private portalCurrencyPipe: PortalCurrencyPipe,
  ) {
    super();
  }

  ngAfterViewInit() {
    const sub = this.form.valueChanges.subscribe(() => this.valueChange.emit());

    this.valueChanges$.add(sub);
  }

  override getFields(): FormlyFieldConfig[] {
    const clientBalanceAmountOpt = this.getClientBalanceOption();

    return [
      {
        fieldGroup: [
          {
            type: 'dropdown',
            className: 'mb-4',
            props: {
              options: of([clientBalanceAmountOpt]),
              disabled: true,
            },
            defaultValue: clientBalanceAmountOpt.value,
          },
          {
            key: 'clientBalanceAmount',
            type: 'currency',
            className: 'mb-4',
            props: {
              label: 'Amount due',
              required: true,
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const sub = field.formControl?.valueChanges.subscribe((value) => {
                  const cardAmount = this.totalAmount - value;

                  const formValue = { cardAmount: cardAmount > 0 ? cardAmount : 0 };

                  this.form.patchValue(formValue, { emitEvent: false });
                });

                this.valueChanges$.add(sub);
              },
            },
            expressions: {
              'props.max': () => this.maxBalanceAmount,
              defaultValue: () =>
                this.maxBalanceAmount > this.totalAmount ? this.totalAmount : this.maxBalanceAmount,
            },
          },
          {
            type: 'info-block',
            props: {
              text: `The maximum you can pay at this time is ${this.transformPortalCurrencyPipe(
                this.maxBalanceAmount,
              )}.`,
            },
          },
        ],
        className: 'mb-3',
        wrappers: ['card-wrapper'],
        expressions: {
          hide: this.isSplittedPayment$.pipe(map((vl) => !vl)),
          'props.disabled': this.isSplittedPayment$.pipe(map((vl) => !vl)),
        },
      },
      {
        fieldGroup: [
          {
            key: 'id',
            type: 'dropdown',
            className: 'mb-4',
            props: {
              placeholder: 'Payment method',
              options: this.isSplittedPayment$.pipe(
                switchMap((isSplittedPayment) => {
                  const options = [...(this.creditCards || [])];

                  if (!isSplittedPayment && this.clientBalanceAmount) {
                    options.push(clientBalanceAmountOpt);
                  }

                  return of(options);
                }),
              ),
            },
            defaultValue: newCreditCardOption.value,
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                let prevValue: unknown = field.formControl?.value;

                const sub = field.formControl?.valueChanges.subscribe((value) => {
                  if (value === prevValue) return;

                  prevValue = value;

                  if (value === newCreditCardOption.value) {
                    const clientBalanceAmount =
                      this.maxBalanceAmount > this.totalAmount
                        ? this.totalAmount
                        : this.maxBalanceAmount;

                    const formValue = {
                      id: newCreditCardOption.value,
                      clientBalanceAmount,
                      cardAmount: this.totalAmount - clientBalanceAmount,
                    };

                    this.form.reset(formValue);
                  } else if (value === clientBalanceOption.value) {
                    const needsSplit = this.maxBalanceAmount < this.totalAmount;

                    if (needsSplit) {
                      this.split();
                    }
                  } else {
                    const foundCreditCard = this.creditCards?.find((p) => p.value === value);

                    const formValue = { ...(foundCreditCard?.extra || {}) };

                    this.form.patchValue(formValue);
                  }
                });

                this.valueChanges$.add(sub);
              },
            },
            expressions: {
              hide: () => !this.creditCards?.length && !this.clientBalanceAmount,
            },
          },
          {
            key: 'number',
            type: 'cc-number',
            className: 'mb-4',
            props: {
              label: 'Debit/Credit card number',
              required: true,
              placeholder: '0000 0000 0000 0000',
            },
            validators: {
              'cc-number-invalid': CCNumberValidator,
            },
            expressions: {
              hide: this.isNotNewCreditCard.bind(this),
              'props.disabled': this.isNotNewCreditCard.bind(this),
            },
          },
          {
            key: 'holder',
            type: 'input',
            className: 'mb-4',
            props: {
              label: 'Name on Card',
              required: true,
              attributes: {
                autocomplete: 'cc-name',
              },
            },
            expressions: {
              hide: this.isClientBalance.bind(this),
              'props.disabled': this.isNotNewCreditCard.bind(this),
            },
          },
          {
            fieldGroup: [
              {
                key: 'expiration',
                type: 'cc-expiration',
                className: 'mr-2 flex-1',
                props: {
                  label: 'Expiration date',
                  required: true,
                  placeholder: 'MM/YY',
                },
                expressions: {
                  hide: this.isClientBalance.bind(this),
                  'props.disabled': this.isNotNewCreditCard.bind(this),
                },
              },
              {
                key: 'cvv2',
                type: 'cc-cvv',
                className: 'ml-2 flex-1',
                props: {
                  label: 'Security code',
                  required: true,
                },
                expressions: {
                  hide: this.isClientBalance.bind(this),
                  'props.disabled': this.isClientBalance.bind(this),
                },
              },
            ],
            fieldGroupClassName: 'd-flex',
          },
          {
            key: 'countryId',
            type: 'dropdown',
            className: 'mb-4 mt-4',
            props: {
              label: 'Country',
              placeholder: 'Select country',
              required: true,
              options: this.apiService.countries().pipe(
                map((options) => {
                  const separatedOptions = ['US', 'GB', 'CA', 'AU'].map((code) => {
                    const index = options.findIndex(({ extra }) => extra?.code === code);
                    return options.splice(index, 1)[0];
                  });

                  this.usCountryCode = separatedOptions[0]?.value;

                  return [...separatedOptions, { value: dropdownSeparatorValue }, ...options];
                }),
              ),
            },
            expressions: {
              hide: this.isNotNewCreditCard.bind(this),
              'props.disabled': this.isNotNewCreditCard.bind(this),
            },
          },
          {
            key: 'billingAddress1',
            type: 'input',
            className: 'mb-4',
            props: {
              label: 'Address line 1',
              required: true,
            },
            expressions: {
              hide: this.isNotNewCreditCard.bind(this),
              'props.disabled': this.isNotNewCreditCard.bind(this),
            },
          },
          {
            key: 'billingAddress2',
            type: 'input',
            className: 'mb-4',
            props: {
              label: 'Address line 2 (optional)',
            },
            expressions: {
              hide: this.isNotNewCreditCard.bind(this),
            },
          },
          {
            fieldGroup: [
              {
                key: 'state',
                type: 'dropdown',
                className: 'mr-2 flex-1',
                props: {
                  label: 'State',
                  placeholder: 'Select state',
                  required: true,
                  options: this.apiService.states(),
                },
                expressions: {
                  hide: () => {
                    const countryId = (this.model as Partial<{ countryId: string }>)['countryId'];

                    return (
                      (countryId && countryId !== this.usCountryCode) || this.isNotNewCreditCard()
                    );
                  },
                  'props.disabled': this.isNotNewCreditCard.bind(this),
                },
              },
              {
                key: 'city',
                type: 'input',
                props: {
                  label: 'City',
                  required: true,
                },
                expressions: {
                  className: () => {
                    const countryId = (this.model as Partial<{ countryId: string }>)['countryId'];

                    return countryId
                      ? countryId === this.usCountryCode
                        ? 'ml-2 flex-1'
                        : 'flex-1'
                      : 'ml-2 flex-1';
                  },
                  hide: this.isNotNewCreditCard.bind(this),
                  'props.disabled': this.isNotNewCreditCard.bind(this),
                },
              },
            ],
            fieldGroupClassName: 'd-flex',
          },
          {
            key: 'zipCode',
            type: 'input',
            className: 'mt-4 pr-2 w-50',
            props: {
              label: 'ZIP code',
              required: true,
            },
            expressions: {
              hide: this.isNotNewCreditCard.bind(this),
              'props.disabled': this.isNotNewCreditCard.bind(this),
            },
          },
          {
            type: 'info-block',
            props: {
              text: `An amount of ${this.transformPortalCurrencyPipe(
                this.clientBalanceAmount > this.totalAmount
                  ? this.totalAmount
                  : this.clientBalanceAmount,
              )} will be deducted from your balance.`,
            },
            expressions: {
              hide: this.isNotClientBalance.bind(this),
              'props.disabled': this.isNotClientBalance.bind(this),
            },
          },
          {
            key: 'cardAmount',
            type: 'currency',
            props: {
              label: 'Amount due',
              required: true,
            },
            className: 'mt-4',
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const sub = field.formControl?.valueChanges.subscribe((value) => {
                  const clientBalanceAmount = this.totalAmount - value;

                  const formValue = {
                    clientBalanceAmount: clientBalanceAmount > 0 ? clientBalanceAmount : 0,
                  };

                  this.form.patchValue(formValue, { emitEvent: false });
                });

                this.valueChanges$.add(sub);
              },
            },
            expressions: {
              hide: this.isSplittedPayment$.pipe(map((vl) => !vl)),
              'props.disabled': this.isSplittedPayment$.pipe(map((vl) => !vl)),
              'props.max': () => {
                const clientBalanceAmount = this.form.get('clientBalanceAmount')?.value || 0;

                return this.totalAmount - clientBalanceAmount;
              },
            },
          },
        ],
        wrappers: ['card-wrapper'],
      },
    ];
  }

  isNotNewCreditCard() {
    const idControl = this.form.get('id');
    return Boolean(idControl?.value !== newCreditCardOption.value && this.creditCards?.length);
  }

  isClientBalance() {
    const idControl = this.form.get('id');
    return idControl?.value === clientBalanceOption.value;
  }

  isNotClientBalance() {
    const idControl = this.form.get('id');
    return idControl?.value !== clientBalanceOption.value;
  }

  split() {
    this.isSplittedPayment$.next(true);

    const clientBalanceAmount =
      this.maxBalanceAmount > this.totalAmount ? this.totalAmount : this.maxBalanceAmount;

    const formValue = {
      id: newCreditCardOption.value,
      clientBalanceAmount,
      cardAmount: this.totalAmount - clientBalanceAmount,
    };

    setTimeout(() => this.form.patchValue(formValue)); // todo timeout

    const lead_portal_link = this.portalLink;
    window.track({ event_name: 'switch_on_split', lead_portal_link });
  }

  merge() {
    this.isSplittedPayment$.next(false);

    const lead_portal_link = this.portalLink;
    window.track({ event_name: 'switch_off_split', lead_portal_link });
  }

  transformPortalCurrencyPipe(n: number) {
    return this.portalCurrencyPipe.transform(n) as string;
  }

  getClientBalanceOption() {
    return {
      value: clientBalanceOption.value,
      label: `${clientBalanceOption.label}: ${this.transformPortalCurrencyPipe(
        this.clientBalanceAmount,
      )}`,
    } as Option;
  }

  override submit() {
    this.form.markAllAsTouched();

    if (this.form.valid && this.readAndAccept) {
      this.disabledFields = Object.keys(this.form.controls).filter(
        (key) => this.form.get(key)?.disabled,
      );
      this.form.disable();
      this.formSubmit.emit(this.model);
    } else if (this.form.invalid) {
      this.form.setErrors({ reqired: errorsFormTexts.required });
      this.scrollToFirstError();
    } else {
      this.setReadAndAcceptError.emit(true);
      scrollTo('#read-and-accept');
    }

    const lead_portal_link = this.portalLink;
    window.track({ event_name: 'submit_card', lead_portal_link });
  }

  override setErrors(errors: CreditCardErrors) {
    const keys = Object.keys(errors.credit_card || {});

    if (keys?.length) {
      this.form.enable();

      keys.forEach((key) => {
        const field = this.form.get(key);

        field?.setErrors({ backend: (errors.credit_card || {})[key] });
      });

      this.disabledFields.forEach((key) => {
        const field = this.form.get(key);

        field?.disable();
      });

      this.form.setErrors({ backend: errorsFormTexts.card });

      this.scrollToFirstError();
    }
    if (errors.error) {
      this.form.enable();
      this.form.setErrors({ backend: errorsFormTexts.optionSubmitError });
    }
  }

  ngOnDestroy() {
    this.valueChanges$?.unsubscribe();
  }
}

export type CreditCardErrors = {
  error?: string;
  passengers?: {
    birthday?: string;
  }[];
  credit_card?: {
    [key: string]: string;
  };
};
