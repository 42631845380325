import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { catchError, combineLatest, EMPTY, map, Observable, take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { Steps } from '@app/components/stepper/stepper.component';
import { PortalLeadService } from '@app/services/portal-lead/portal-lead.service';
import { PortalOrderService } from '@app/services/portal-order/portal-order.service';
import {
  PortalFeatures,
  PortalLeadNode,
  PortalOrderNode,
  PortalPassengerNodeInput,
} from '@app/services/api/api.types';
import { PopupsService } from '@app/ui/services/popups.service';
import { State, StateService } from '@app/services/state/state.service';
import { beforeUnloadHandler, mapPortalPassengerNode } from '@app/utils/utils';
import { ApiService } from '@app/services/api/api.service';
import { Option } from '@app/forms/formly/formly-utils';
import { newTravalerOption } from '@app/utils/constants';

@Component({
  selector: 'passengers-page',
  templateUrl: './passengers-page.component.html',
  styleUrls: ['./passengers-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengersPageComponent implements OnInit, OnDestroy {
  Steps = Steps;

  portalLead$: Observable<PortalLeadNode | null>;

  portalOrder: PortalOrderNode;
  state: State;
  clientPassengers: Option[];

  passengersLabels: string[] = [];

  model: { passengers: PortalPassengerNodeInput[] } = { passengers: [] };
  errors: PassengersErrors | null;

  formHasChanges: boolean;
  withPayAndConfirm: boolean;

  @ViewChild('confirmTemplate') confirmTemplate: TemplateRef<unknown>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private portalLeadService: PortalLeadService,
    private portalOrderService: PortalOrderService,
    private activatedRoute: ActivatedRoute,
    private stateService: StateService,
    private popupsService: PopupsService,
    private apiService: ApiService,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit() {
    this.getPortalLead();
  }

  getPortalLead() {
    this.portalLead$ = combineLatest([
      this.portalLeadService.portalLead$,
      this.portalOrderService.portalOrder$,
    ]).pipe(
      map(([portalLead, portalOrder]) => {
        if (portalLead) {
          this.withPayAndConfirm =
            portalLead.salesAgent.portalFeatures.includes(PortalFeatures.portal_cc) &&
            !portalOrder?.isDemo;
        }

        if (portalOrder) {
          this.portalOrder = portalOrder;
          this.model.passengers.length = portalOrder.totalPassengers;

          this.passengersLabels = makePassengersLabels(portalOrder);
        }

        this.state = this.stateService.getState();
        this.clientPassengers = this.stateService.getClientPassengers();

        if (this.state.passengers.length) {
          this.model = { passengers: this.state.passengers };
        }

        const shouldOptionSelect =
          this.activatedRoute.snapshot.queryParamMap.get('utm_source') !== 'agent_preview';

        if (shouldOptionSelect) {
          this.optionSelected();
        }

        return portalLead;
      })
    );
  }

  valueChange() {
    this.formHasChanges = true;
    window.addEventListener('beforeunload', beforeUnloadHandler);
  }

  tryBack() {
    if (this.formHasChanges) {
      this.popupsService.showModal(this.confirmTemplate);
      return;
    }

    this.back();
  }

  back() {
    this.location.back();
  }

  formSubmit(data: { passengers: PortalPassengerNodeInput[] }) {
    const input = {
      portalLink: this.portalOrder.portalLink,
      passengers: data.passengers.map((p) => ({ ...p })),
    };

    input.passengers.forEach((p) => {
      if (p.id === newTravalerOption.value) {
        delete (p as Partial<{ id: unknown }>)['id'];
      }
    });

    return this.withPayAndConfirm
      ? this.portalSubmitPassengers({ input })
      : this.portalSubmitOption(input);
  }

  portalSubmitOption(input: { passengers: PortalPassengerNodeInput[]; portalLink: string }) {
    return this.apiService
      .portalSubmitOption({ input })
      .pipe(
        take(1),
        catchError((error: Error) => {
          console.log(error.message || error);
          return EMPTY;
        })
      )
      .subscribe((response) => {
        if (response?.ok) {
          this.portalLeadService.getPortalLead({ portalLink: this.state.portalLink });

          this.continue();
        } else {
          this.errors = JSON.parse(response.errors) as PassengersErrors;
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  continue() {
    const path = [
      `${this.state.portalLink}`,
      `${this.portalOrder.portalLink}`,
      this.withPayAndConfirm
        ? this.portalOrder.isDisruptionProtectionEnabled
          ? `premium-disruption-assistance`
          : this.portalOrder.isCancelForAnyReasonEnabled
          ? `cancel-for-any-reason`
          : this.portalOrder.priceDropProtectionEnabled
          ? `price-drop-protection`
          : `gratitude`
        : `thank-you`,
    ];

    void this.router.navigate(path);
  }

  portalSubmitPassengers(variables: {
    input: {
      portalLink: string;
      passengers: PortalPassengerNodeInput[];
    };
  }) {
    return this.apiService
      .portalSubmitPassengers(variables)
      .pipe(
        take(1),
        catchError((error: Error) => {
          console.log(error.message || error);
          return EMPTY;
        })
      )
      .subscribe((response) => {
        if (response?.ok) {
          this.errors = null;

          this.stateService.patchState({ passengers: variables.input.passengers });

          if (response.passengers?.length) {
            const clientPassengers = response.passengers.map(mapPortalPassengerNode);

            if (clientPassengers.length) {
              clientPassengers.unshift(newTravalerOption);
            }

            this.stateService.setClientPassengers(clientPassengers);
          }

          this.continue();
        } else {
          this.errors = JSON.parse(response.errors) as PassengersErrors;
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  optionSelected() {
    this.apiService
      .portalOptionSelected({ portalLink: this.portalOrder.portalLink })
      .pipe(take(1))
      .subscribe();
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  }
}

export enum PassengerTypeLabel {
  Adult = 'Adult',
  Child = 'Child, 2-11',
  Infant = 'Infant, under age 2',
}

export type PassengersErrors = {
  passengers?: {
    birthday?: string;
  }[];
};

const makePassengersLabels = (portalOrder: PortalOrderNode) => {
  const passengersLabels = [];

  if (portalOrder) {
    for (let i = 0; i < portalOrder.totalPassengers; i++) {
      const label =
        i < portalOrder.numberOfAdults
          ? PassengerTypeLabel.Adult
          : i < portalOrder.numberOfAdults + portalOrder.numberOfChildren
          ? PassengerTypeLabel.Child
          : PassengerTypeLabel.Infant;

      passengersLabels.push(`Traveler ${i + 1}: ${label}`);
    }
  }

  return passengersLabels;
};
