import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PortalLeadNode, PortalTripType } from '@app/services/api/api.types';

@Component({
  selector: 'trip-item',
  templateUrl: './trip-item.component.html',
  styleUrls: ['./trip-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripItemComponent {
  PortalTripType = PortalTripType;

  @Input() portalLead: PortalLeadNode;
}
