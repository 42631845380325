import { Injectable } from '@angular/core';

import { PortalPassengerNodeInput } from '@app/services/api/api.types';
import { Option } from '@app/forms/formly/formly-utils';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  protected state: State = { ...defaultState };

  protected clientPassengers: Option[];
  protected clientCreditCards: Option[];

  protected clientBalance: number;

  getState() {
    return this.state;
  }

  setState(state: State) {
    this.state = state;
  }

  patchState(state: Partial<State>) {
    this.state = { ...this.state, ...state };
  }

  resetState() {
    this.state = { ...defaultState, portalLink: this.state.portalLink };
  }

  setClientPassengers(clientPassengers: Option[]) {
    this.clientPassengers = clientPassengers;
  }

  getClientPassengers() {
    return this.clientPassengers;
  }

  setClientCreditCards(clientCreditCards: Option[]) {
    this.clientCreditCards = clientCreditCards;
  }

  getClientCreditCards() {
    return this.clientCreditCards;
  }

  setClientBalance(clientBalance: number) {
    this.clientBalance = clientBalance;
  }

  getClientBalance() {
    return this.clientBalance;
  }
}

const defaultState: State = {
  portalLink: '',
  passengers: [],
};

export type State = {
  portalLink: string;
  passengers: PortalPassengerNodeInput[];
  disruptionProtection?: number;
  tipsAmount?: number;
  cancelForAnyReason?: number;
  priceDropProtection?: number;
};
