import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'info-block-field',
  templateUrl: './info-block-field.component.html',
  styleUrls: ['./info-block-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBlockFieldComponent extends FieldType<FieldTypeConfig> {}
