<ng-container *ngIf="portalLead$ | async as portalLead">
  <flight-info [portalOrder]="portalOrder" class="mx-4 mx-md-0"></flight-info>

  <stepper
    [step]="Steps.OverviewAndPayment"
    [withPayAndConfirm]="withPayAndConfirm"
    class="mt-3 mt-md-4 mx-4 mx-md-0"
  ></stepper>

  <div class="my-4 my-md-8 d-flex flex-column flex-md-row">
    <div class="flex-1">
      <h2 class="semi-bold mb-4 mx-4 mx-md-0">Trip summary</h2>

      <portal-option-details
        [option]="portalOrder"
        [portalLink]="state.portalLink"
        class="option-details"
      ></portal-option-details>

      <card *ngIf="portalOrder.hotelBookings?.length" class="mt-4 d-flex">
        <hotel-bookings
          [hotelBookings]="portalOrder.hotelBookings"
          class="w-100 hotel-bookings"
        ></hotel-bookings>
      </card>

      <h2 class="semi-bold mt-4 mt-md-8 mb-4 mx-4 mx-md-0">Travelers</h2>

      <passengers-preview [passengers]="state.passengers"></passengers-preview>

      <card-form
        (valueChange)="valueChange()"
        (formSubmit)="formSubmit($event)"
        (setReadAndAcceptError)="readAndAcceptError = $event"
        (back)="tryBack()"
        [model]="model"
        [errors]="errors"
        [clientCreditCards]="clientCreditCards"
        [readAndAccept]="readAndAccept"
        [portalLink]="state.portalLink"
        [clientBalance]="clientBalance"
        [maxBalanceAmount]="portalOrder.maxBalanceAmount"
        [totalAmount]="(portalOrder.soldAmount || 0) + (state.disruptionProtection || 0) + (state.tipsAmount || 0) + (state.cancelForAnyReason || 0) + (state.priceDropProtection || 0)"
        class="mt-4 mt-md-8"
      >
        <div terms class="mt-4 mt-md-8">
          <h2 class="semi-bold mb-4 mx-4 mx-md-0">Terms & conditions</h2>

          <term-of-use
            (selectReadAndAccept)="selectReadAndAccept($event)"
            [disclaimers]="(state.disruptionProtection || state.cancelForAnyReason || state.priceDropProtection) ? portalOrder.disclaimersWithAdditionalProductsHtml : portalOrder.disclaimersHtml"
            [readAndAccept]="readAndAccept"
            [readAndAcceptError]="readAndAcceptError"
          ></term-of-use>
        </div>

        <div class="d-md-none mt-4">
          <h2 class="semi-bold mb-4 mx-4">Price summary</h2>

          <price-summary
            [option]="portalOrder"
            [disruptionProtection]="state.disruptionProtection"
            [tipsAmount]="state.tipsAmount"
            [cancelForAnyReason]="state.cancelForAnyReason"
            [priceDropProtection]="state.priceDropProtection"
          ></price-summary>
        </div>
      </card-form>
    </div>

    <div class="d-none d-md-block ml-md-4 summary">
      <h2 class="semi-bold mb-4">Price summary</h2>

      <price-summary
        [option]="portalOrder"
        [disruptionProtection]="state.disruptionProtection"
        [tipsAmount]="state.tipsAmount"
        [cancelForAnyReason]="state.cancelForAnyReason"
        [priceDropProtection]="state.priceDropProtection"
      ></price-summary>
    </div>
  </div>
</ng-container>

<ng-template #confirmTemplate>
  <go-back-confirm (back)="back()"></go-back-confirm>
</ng-template>
