import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { catchError, combineLatest, EMPTY, map, Observable, take } from 'rxjs';

import { Steps } from '@app/components/stepper/stepper.component';
import { PortalLeadNode, PortalOrderNode } from '@app/services/api/api.types';
import { PortalLeadService } from '@app/services/portal-lead/portal-lead.service';
import { PortalOrderService } from '@app/services/portal-order/portal-order.service';
import { Router } from '@angular/router';
import { StateService } from '@app/services/state/state.service';
import { AuthService } from '@app/services/auth/auth.service';

@Component({
  selector: 'thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThankYouPageComponent implements OnInit {
  Steps = Steps;

  portalLead$: Observable<PortalLeadNode | null>;

  portalOrder: PortalOrderNode;

  constructor(
    private portalLeadService: PortalLeadService,
    private portalOrderService: PortalOrderService,
    private stateService: StateService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getPortalLead();
  }

  getPortalLead() {
    this.portalLead$ = combineLatest([
      this.portalLeadService.portalLead$,
      this.portalOrderService.portalOrder$,
    ]).pipe(
      map(([portalLead, portalOrder]) => {
        if (portalOrder) {
          this.portalOrder = portalOrder;
        }

        return portalLead;
      }),
    );
  }

  goBack() {
    const portalLink = this.stateService.getState().portalLink;

    this.authService
      .hasSession()
      .pipe(
        take(1),
        map((hasSession) => {
          if (hasSession) {
            return this.router.navigate([`bookings-and-trips`]);
          }
          return this.router.navigate([`${portalLink}`]);
        }),
        catchError((error: Error) => {
          console.log(error.message || error);
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
