<input
  [formControl]="formControl"
  [formlyAttributes]="field"
  [ngClass]="{ 'error': formControl.touched && formControl.errors }"
  [attr.data-id]="field.key"
  placeholder="$"
  class="input"
  autocomplete="off"
  prefix="$"
  mask="separator.2"
  thousandSeparator=","
/>
