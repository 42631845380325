<div>
  <h3 class="semi-bold">
    {{ portalLead.firstTrip?.departureAirport?.cityName }} ({{
    portalLead.firstTrip?.departureAirport?.locationCode }}) – {{
    portalLead.firstTrip?.arrivalAirport?.cityName }} ({{
    portalLead.firstTrip?.arrivalAirport?.locationCode }})
  </h3>

  <div class="d-flex align-items-center flex-wrap mt-2">
    <p data-id="service-class">{{ portalLead.firstTrip?.serviceClass | label }}</p>

    <dot-separator class="mx-2"></dot-separator>

    <ng-container *ngIf="portalLead.tripType">
      <ng-container [ngSwitch]="portalLead.tripType">
        <p *ngSwitchCase="PortalTripType.Oneway" data-id="trip-type">One way</p>
        <p *ngSwitchCase="PortalTripType.Round" data-id="trip-type">Round trip</p>
        <p *ngSwitchCase="PortalTripType.Multi" data-id="trip-type">Multi-city</p>
      </ng-container>
    </ng-container>

    <dot-separator class="mx-2"></dot-separator>

    <p data-id="departure-at">
      {{ portalLead.firstTrip?.departureAt | date: 'MMM d' }}<ng-container
        *ngIf="portalLead.roundTripDepartureAt"
        >–{{ portalLead.roundTripDepartureAt | date: 'MMM d' }}</ng-container
      >
    </p>

    <dot-separator class="mx-2"></dot-separator>

    <p data-id="passenger-count">{{ portalLead.firstTrip?.passengerCount }} pax</p>

    <dot-separator class="mx-2"></dot-separator>

    <p data-id="portal-link">Trip #{{ portalLead.portalLink }}</p>
  </div>
</div>
