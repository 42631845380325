import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PortalOrderNode } from '@app/services/api/api.types';

@Component({
  selector: 'price-drop-select',
  templateUrl: './price-drop-select.component.html',
  styleUrls: ['./price-drop-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceDropSelectComponent {
  @Input() priceDropProtection?: number;
  @Input() option: PortalOrderNode;

  @Output() selectPriceDropProtection = new EventEmitter<number>();
}
