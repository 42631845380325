<card class="flex-column p-4">
  <div class="d-flex align-items-center">
    <img src="assets/icons/planner_review.svg" alt="planner_review" width="24" height="24" />

    <h2 class="semi-bold ml-1">Price drop protection</h2>
  </div>

  <h3 class="semi-bold mt-1">Lock in savings and peace of mind</h3>

  <p class="mt-1">
    Airline prices shift every 2.4 days on average, so early booking is key. Our Price Drop
    Protection tracks your fare up to three times daily until 24 hours before departure, refunding
    any price drops directly to your account. Save effortlessly with automatic refunds and stay
    updated with our Price Change Monitor.
  </p>

  <div class="d-flex d-md-none flex-column mt-4">
    <card
      (click)="selectPriceDropProtection.emit(option.priceDropProtectionAmount)"
      [ngClass]="{ 'selected': priceDropProtection === option.priceDropProtectionAmount }"
      class="flex-column price-drop-points"
      data-id="select-price-drop"
    >
      <ng-container *ngTemplateOutlet="priceDropHead"></ng-container>
      <ng-container *ngTemplateOutlet="priceDropPoints"></ng-container>
    </card>

    <card
      (click)="selectPriceDropProtection.emit(0)"
      [ngClass]="{ 'selected': priceDropProtection === 0 }"
      class="flex-column points mt-2"
      data-id="unselect-price-drop"
    >
      <ng-container *ngTemplateOutlet="head"></ng-container>
      <ng-container *ngTemplateOutlet="points"></ng-container>
    </card>
  </div>

  <div class="d-none d-md-flex flex-column mt-4">
    <card *ngIf="priceDropProtection !== 0" class="price-drop-points">
      <ng-container *ngTemplateOutlet="priceDropPoints"></ng-container>
    </card>

    <card *ngIf="priceDropProtection === 0" class="points">
      <ng-container *ngTemplateOutlet="points"></ng-container>
    </card>

    <card
      (click)="selectPriceDropProtection.emit(option.priceDropProtectionAmount)"
      [ngClass]="{ 'selected': priceDropProtection === option.priceDropProtectionAmount }"
      class="cursor-pointer mt-2 price-drop-points"
      data-id="select-price-drop"
    >
      <ng-container *ngTemplateOutlet="priceDropHead"></ng-container>
    </card>

    <card
      (click)="selectPriceDropProtection.emit(0)"
      [ngClass]="{ 'selected': priceDropProtection === 0 }"
      class="cursor-pointer mt-2 points"
      data-id="unselect-price-drop"
    >
      <ng-container *ngTemplateOutlet="head"></ng-container>
    </card>
  </div>
</card>

<ng-template #priceDropHead>
  <div class="d-flex flex-column p-3 w-100 price-drop-head">
    <div class="d-flex align-items-start nowrap">
      <img
        src="assets/icons/planner_review_green.svg"
        alt="planner_review_green"
        width="20"
        height="20"
      />

      <p class="ml-1">Yes, I want Price drop protection</p>

      <p class="ml-4 text-jazlyn-100 caption badge">Recommended</p>

      <radio
        [selected]="priceDropProtection === option.priceDropProtectionAmount"
        class="ml-auto"
      ></radio>
    </div>

    <div class="d-flex mt-2 ml-6">
      <h3 class="semi-bold" data-id="per-traveler-amount">
        {{ option.priceDropProtectionAmount | portalCurrency }}
      </h3>

      <p>/traveler</p>
    </div>
  </div>
</ng-template>

<ng-template #priceDropPoints>
  <div class="d-flex flex-column p-3">
    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/check_600.svg" alt="check_600" width="20" height="20" />

        <p class="semi-bold ml-1">Frequent price monitoring</p>
      </div>

      <p class="ml-6 mt-1">
        We reprice your trip three times a day until 48 hours before departure to secure the best
        deal.
      </p>
    </div>

    <hr class="my-3 price-drop-points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/check_600.svg" alt="check_600" width="20" height="20" />

        <p class="semi-bold ml-1">Guaranteed best price</p>
      </div>

      <p class="ml-6 mt-1">
        Your fare stays competitive even after booking, ensuring you always pay the lowest price.
      </p>
    </div>

    <hr class="my-3 price-drop-points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/check_600.svg" alt="check_600" width="20" height="20" />

        <p class="semi-bold ml-1">Automatic refunds</p>
      </div>

      <p class="ml-6 mt-1">
        If your ticket price drops, we’ll refund the difference automatically.
      </p>
    </div>
  </div>
</ng-template>

<ng-template #head>
  <div class="d-flex flex-column p-3 w-100 head">
    <div class="d-flex align-items-start nowrap">
      <img
        src="assets/icons/planner_review_red.svg"
        alt="planner_review_red"
        width="20"
        height="20"
      />

      <p class="ml-1">No, I decline any refund if the price drops</p>

      <radio [selected]="priceDropProtection === 0" class="ml-auto"></radio>
    </div>

    <div class="d-flex mt-2 ml-6">
      <h3 class="semi-bold">{{ 0 | portalCurrency }}</h3>
    </div>
  </div>
</ng-template>

<ng-template #points>
  <div class="d-flex flex-column p-3">
    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/close_600.svg" alt="close_600" width="20" height="20" />

        <p class="semi-bold ml-1">No fare monitoring</p>
      </div>

      <p class="ml-6 mt-1">
        You won’t receive alerts or benefits from price changes for your trip.
      </p>
    </div>

    <hr class="my-3 points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/close_600.svg" alt="close_600" width="20" height="20" />

        <p class="semi-bold ml-1">No automatic refunds</p>
      </div>

      <p class="ml-6 mt-1">
        Any drop in ticket prices will not result in a refund or credit to your account.
      </p>
    </div>

    <hr class="my-3 points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/close_600.svg" alt="close_600" width="20" height="20" />

        <p class="semi-bold ml-1">Standard ticket policies apply</p>
      </div>

      <p class="ml-6 mt-1">
        Your booking remains subject to the airline’s general pricing terms without additional
        protection.
      </p>
    </div>
  </div>
</ng-template>
