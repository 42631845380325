<div *ngIf="authorizedData$ | async as authorizedData; else loadingTemplate" class="mb-4 mb-md-10">
  <div class="d-flex mx-4 mx-md-0">
    <tab
      *ngFor="let tab of tabs"
      (click)="activeTab = tab.key"
      [active]="activeTab === tab.key"
      data-id="tab"
    >
      <img
        src="assets/icons/{{ tab.icon }}.svg"
        width="20"
        height="20"
        alt="{{ tab.icon }}"
        class="mr-2"
      />
      <p class="caption semi-bold">{{ tab.key }}</p>
    </tab>
  </div>

  <ng-container [ngSwitch]="activeTab">
    <div *ngSwitchCase="HomePageTabs.Quotes">
      <ng-container *ngIf="authorizedData.quotes.length; else emptyTemplate">
        <a
          *ngFor="let portalLead of authorizedData.quotes"
          routerLink="/{{ portalLead.portalLink }}"
          class="trip-link"
          data-id="quote"
        >
          <trip-item [portalLead]="portalLead" class="mt-4"></trip-item>
        </a>
      </ng-container>
    </div>

    <div *ngSwitchCase="HomePageTabs.BookingsAndTrips">
      <div class="mt-4 px-4 py-3 block">
        <p class="semi-bold">
          If you need any help with your flight details, just reach out to your travel agent.
        </p>
      </div>

      <h3 class="semi-bold mt-8 mx-4 mx-md-0">Upcoming</h3>
      <ng-container *ngIf="authorizedData.upcomingTrips.length; else emptyTemplate">
        <a
          *ngFor="let portalLead of authorizedData.upcomingTrips"
          routerLink="/{{ portalLead.portalLink }}"
          class="trip-link"
          data-id="upcoming-trip"
        >
          <trip-item [portalLead]="portalLead" class="mt-4"></trip-item>
        </a>
      </ng-container>

      <h3 class="semi-bold mt-8 mx-4 mx-md-0">Past</h3>
      <ng-container *ngIf="authorizedData.pastTrips.length; else emptyTemplate">
        <a
          *ngFor="let portalLead of authorizedData.pastTrips"
          routerLink="/{{ portalLead.portalLink }}"
          class="trip-link"
          data-id="past-trip"
        >
          <trip-item [portalLead]="portalLead" class="mt-4 inactive"></trip-item>
        </a>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <div class="d-md-none px-4">
    <div class="d-flex flex-column w-40">
      <skeleton [height]="24"></skeleton>
      <skeleton [height]="20" class="mt-2"></skeleton>
    </div>
    <skeleton [height]="288" class="mt-4"></skeleton>
    <skeleton [height]="84" class="mt-4"></skeleton>
    <skeleton [height]="540" class="mt-4"></skeleton>
    <skeleton [height]="540" class="mt-4"></skeleton>
    <skeleton [height]="540" class="mt-4"></skeleton>
  </div>

  <div class="d-none d-md-flex flex-column">
    <div class="d-flex flex-column w-40">
      <skeleton [height]="40"></skeleton>
      <skeleton [height]="20" class="mt-2"></skeleton>
    </div>
    <skeleton [height]="120" class="mt-8"></skeleton>
    <skeleton [height]="44" class="mt-4"></skeleton>
    <skeleton [height]="280" class="mt-4"></skeleton>
    <skeleton [height]="280" class="mt-4"></skeleton>
    <skeleton [height]="280" class="mt-4"></skeleton>
  </div>
</ng-template>

<ng-template #emptyTemplate>
  <p class="mt-4 mx-4 mx-md-0 text-jazlyn-700">No records found</p>
</ng-template>
