<card class="flex-1">
  <div class="p-4 pt-0 w-100" data-id="price-summary">
    <div
      *ngIf="option.soldAmountPerAdult && option.numberOfAdults"
      class="d-flex justify-content-between mt-4"
    >
      <p class="text-jazlyn-700">
        {{ option.soldAmountPerAdult | portalCurrency }} x {{ option.numberOfAdults }} adults
      </p>

      <p class="text-jazlyn-700">
        {{ (option.soldAmountPerAdult * option.numberOfAdults) | portalCurrency }}
      </p>
    </div>

    <div
      *ngIf="option.soldAmountPerChild && option.numberOfChildren"
      class="d-flex justify-content-between mt-4"
    >
      <p class="text-jazlyn-700">
        {{ option.soldAmountPerChild | portalCurrency }} x {{ option.numberOfChildren }} children
      </p>

      <p class="text-jazlyn-700">
        {{ (option.soldAmountPerChild * option.numberOfChildren) | portalCurrency }}
      </p>
    </div>

    <div
      *ngIf="option.soldAmountPerInfant && option.numberOfInfants"
      class="d-flex justify-content-between mt-4"
    >
      <p class="text-jazlyn-700">
        {{ option.soldAmountPerInfant | portalCurrency }} x {{ option.numberOfInfants }} infants
      </p>

      <p class="text-jazlyn-700">
        {{ (option.soldAmountPerInfant * option.numberOfInfants) | portalCurrency }}
      </p>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <p>Flights<ng-container *ngIf="option.hotelBookings?.length"> & Stay</ng-container></p>

      <p data-id="flights">{{ option.soldAmount | portalCurrency }}</p>
    </div>

    <hr class="mt-4" />

    <ng-container *ngIf="disruptionProtection">
      <div class="d-flex justify-content-between mt-4">
        <p>Premium Disruption Assistance</p>

        <p data-id="protection">{{ disruptionProtection | portalCurrency }}</p>
      </div>

      <hr class="mt-4" />
    </ng-container>

    <ng-container *ngIf="cancelForAnyReason">
      <div class="d-flex justify-content-between mt-4">
        <p>Cancel For Any Reason</p>

        <p data-id="cancel-for-any-reason">{{ cancelForAnyReason | portalCurrency }}</p>
      </div>

      <hr class="mt-4" />
    </ng-container>

    <ng-container *ngIf="priceDropProtection">
      <div class="d-flex justify-content-between mt-4">
        <p>Price drop protection</p>

        <p data-id="price-drop">{{ priceDropProtection | portalCurrency }}</p>
      </div>

      <hr class="mt-4" />
    </ng-container>

    <ng-container *ngIf="tipsAmount">
      <div class="d-flex justify-content-between mt-4">
        <p>Gratuity</p>

        <p data-id="tips-amount">{{ tipsAmount | portalCurrency }}</p>
      </div>

      <hr class="mt-4" />
    </ng-container>

    <div class="d-flex justify-content-between align-items-baseline mt-4">
      <p>Total</p>

      <h1 data-id="total">{{ total | portalCurrency }}</h1>
    </div>
  </div>
</card>
