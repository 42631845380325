import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { catchError, EMPTY, Observable } from 'rxjs';

import { ApiService } from '@app/services/api/api.service';
import { StateService } from '@app/services/state/state.service';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent implements OnInit {
  HomePageTabs = HomePageTabs;

  authorizedData$: Observable<any | null>;

  tabs = [
    { key: HomePageTabs.Quotes, icon: 'plane' },
    { key: HomePageTabs.BookingsAndTrips, icon: 'checked_bag_full' },
  ];

  activeTab = HomePageTabs.Quotes;

  constructor(private stateService: StateService, private apiService: ApiService) {}

  ngOnInit() {
    this.getAuthorizedData();
  }

  getAuthorizedData() {
    const portalLink = this.stateService.getState().portalLink;

    const variables = { portalLink };

    this.authorizedData$ = this.apiService.authorizedDataHistory(variables).pipe(
      catchError((error: Error) => {
        console.log(error.message || error);
        return EMPTY;
      })
    );
  }
}

enum HomePageTabs {
  Quotes = 'Quotes',
  BookingsAndTrips = 'Bookings and Trips',
}
